// Import dependencies
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as msal from "@azure/msal-browser";
import * as jose from "jose";
import moment from "moment";
// Import actions
import { fetchLoginData, resetLoginData, fetchPasswordData, resetPostCodeData } from "./store/actions";

// Import component and configuration
import { closeIcon, xanturaLogo } from "../../assets/icons";
import { InputField } from "../../components";
import { ResetPasswordModal } from "./component";
import { Popup } from "../dashboard/component";
import { MSAL_CONFIG, MSAL_LOGINREQUEST } from "../../config";
import { loginFormState } from "../../interfaces";
import { Link } from "react-router-dom";
import { isLogin } from "../../store/actions/ui-actions";
import { TOKEN, USER_DATA, setData } from "../../utils/Storage";


// Component initiliazation
const Auth = () => {

  const refEle: any = useRef(null);

  // call of the hook to dispatch the action for tha API
  const dispatch = useDispatch();

  // Create a new instance of MSAL
  const msalInstance = new msal.PublicClientApplication(MSAL_CONFIG);

  //   getting the response of the API
  const loginData: any = useSelector<any>((state) => state.loginData);
  const passCodeData: any = useSelector<any>((state) => state.passcodeData);

  //   state for the loign
  const [state, setState] = useState<loginFormState>({
    formData: {
      passWord: "",
      userName: "",
    },
    formError: {
      userName: false,
      passWord: false,
    },
    secondValidation: "",
    validate: false,
    showPassCodePopup: false,
    formSubmitted: false,
  });

  const [resetPasswordShow, setResetPasswordShow] = useState(false);
  const [resetPasscodeShow, setResetPasscodeShow] = useState(false);

  //   handle the error and also dispatch the action to the API
  useEffect(() => {
    if (loginData.isSuccess && loginData.data !== null) {
      if (loginData.data.isPasswordChangeRequired) {

        setState({
          ...state,
          secondValidation: loginData.data,
        });

        setResetPasswordShow(true);
      } else if (loginData.data.isPasswordChangeRequired) {

        setState({
          ...state,
          secondValidation: loginData.data,
        });
        setResetPasscodeShow(true);
      } else {
        setState({
          ...state,
          showPassCodePopup: true,
          secondValidation: loginData.data,
        });
      }

      dispatch(resetLoginData());
    } else if (loginData.isError) {
      dispatch(resetLoginData());
    }
  }, [loginData]);

  // function to handle the validation of the form Submit
  useEffect(() => {
    if (state.formData.userName !== "" && state.formData.passWord !== "") {
      setState({ ...state, validate: false });
    } else {
      setState({ ...state, validate: true });
    }
  }, [state.formData]);


  useEffect(() => {
    if (passCodeData.isSuccess && passCodeData.data !== null) {
      const token = passCodeData.data.coreJwtToken;
      const jwtToken: any = jose.decodeJwt(token);
      const epoch = jwtToken?.exp.slice(0, -3)
      const expiryDate = moment.unix(jwtToken?.exp.slice(0, -3)).format("YYYY-MM-DD HH:mm:ss");

      setData(USER_DATA, passCodeData.data)
      setData(TOKEN, { token: passCodeData.data.coreJwtToken, provider: 'legacy', expiryDate, epoch });

      dispatch(isLogin({ token: passCodeData.data.coreJwtToken, isLegacy: true, provider: "legacy" }))
      dispatch(resetPostCodeData());
    } else if (passCodeData.isError) {
      toast.error("Internal Server Error")
      dispatch(resetPostCodeData());
    }
  }, [passCodeData])

  // function to render the header for the popup of passcode validation
  const __renderPassCodePopupHeader = () => {
    return (
      <div className="popup-title">
        <h3 className="title">Passcode</h3>
        <button
          className="close"
          onClick={() => setState({ ...state, showPassCodePopup: false })}
        >
          <img src={closeIcon} alt="Close icon" />
        </button>
      </div>
    );
  };

  const __submitPassCode = (e: any) => {
    e.preventDefault();
    const passCode = e.target[0].value + '' + e.target[1].value + '' + e.target[2].value;
    dispatch(fetchPasswordData({ secondaryAuthenticationPart: passCode, userToken: state.secondValidation.userToken }));
  };

  // function to show the popup with error message for wrong login username and password
  const __renderLoginError = () => {
    return (
      <div className="popup-title">
        <h3 className="title">Login Error</h3>
        <button
          className="close"
          onClick={() => setState({ ...state, formSubmitted: false })}
        >
          <img src={closeIcon} alt="Close icon" />
        </button>
      </div>
    );
  };

  const __onPassCodeChange = (e: any) => {
    setState({
      ...state,
    });
  };

  const __renderLoginContent = () => {
    return (
      <div>
        <h3>Invalid username or password</h3>
      </div>
    );
  };

  const __onValidValueEnter = (e: any, i: number) => {
    if (e.target.value.length === 1) {
      refEle.current?.[i + 1]?.focus()
    }
  }


  const __renderResetPasscodeTitle = () => {
    return (<div className="popup-title">
      <h3 className="title">Reset Passcode</h3>
    </div>)
  }

  // function to render the fields for the popup of passcode validation
  const __renderPassCodeFields = () => {
    return (
      <div className="passcode-form-wrap">
        <p className="passcode-description">
          Please enter the following characters from your passcode
        </p>
        {Array.isArray(state.secondValidation.secondaryAuthenticationPart) &&
          state.secondValidation.secondaryAuthenticationPart.length > 0 ? (
          <form className="passcode-form" onSubmit={(e) => __submitPassCode(e)} ref={refEle}>
            {state.secondValidation.secondaryAuthenticationPart.map(
              (item: any, index: number) => {
                return (
                  <div className="filter-fields" key={index}>
                    <h3 className="title">{item}</h3>
                    <input type="text" name={item} id={"id" + item} onChange={(e) => __onValidValueEnter(e, index)} autoFocus={index === 0} />
                  </div>
                );
              }
            )}
            <button type="submit" className="submit">Submit</button>
          </form>
        ) : null}
      </div>
    );
  };

  // function to handle the change and update the value in the state for the username and password
  const __onChange = (e: any) => {
    if (e.target.value !== "") {
      setState({
        ...state,
        formData: { ...state.formData, [e.target.name]: e.target.value },
        formError: { ...state.formError, [e.target.name]: false },
      });
    } else {
      setState({
        ...state,
        formData: { ...state.formData, [e.target.name]: e.target.value },
        formError: { ...state.formError, [e.target.name]: true },
      });
    }
  };

  //   Function to submit the Login form data
  const __loginFormSubmit = (event: any) => {
    event.preventDefault();
    if (state.formData.userName !== "" && state.formData.passWord !== "") {
      setState({ ...state, validate: false });
    } else {
      setState({
        ...state,
        validate: true,
        formError: { ...state.formError, userName: true, passWord: true },
      });
    }

    // dispatch and API request
    dispatch(fetchLoginData(state.formData));
  };

  const __loginWithAzure = async () => {
    try {
      // initialize MSAL
      await msalInstance.initialize();

      // Attempt to perform Single Sign-On (SSO)
      await msalInstance.acquireTokenPopup(MSAL_LOGINREQUEST).then((res) => {
        // Set the active account upon successful token acquisition
        msalInstance.setActiveAccount(res.account)


        setData(TOKEN, { token: res.accessToken, provider: 'msal' });
        dispatch(isLogin({ token: res.accessToken, isLegacy: false, provider: "msal" }))
      });
    } catch (e) {
      // Set authentication error state if token acquisition fails
      // setAuthError(e)
      console.log(e)
    }
  }

  return (
    <>
      <div className="login-container">
        <div className="image-container">
          <img src={xanturaLogo} alt="Xantura Logo" />
        </div>

        {/* Login form  */}
        <form
          className="need-validated"
          noValidate
          onSubmit={(e) => __loginFormSubmit(e)}
        >
          {/* field to enter username  */}
          <InputField
            title="Login"
            placeholder="Login name"
            name="userName"
            value={state.formData.userName}
            type="text"
            onChange={__onChange}
            error={state.formError.userName}
          />

          {/* field to enter password */}
          <div className="password-container">
            <InputField
              title="Password"
              name="passWord"
              placeholder="Password"
              value={state.formData.passWord}
              type="password"
              onChange={__onChange}
              error={state.formError.passWord}
            />

            {/* TODO: please create forget password page */}
            <Link to="/forgetPassword" title="Forgotten your password?">
              Forgotten your password?
            </Link>
          </div>

          <div className="button-container">
            <button
              disabled={state.validate}
              style={state.validate ? { opacity: 0.5 } : { opacity: 1 }}
              type="submit"
            >
              Login
            </button>
            <span>OR</span>

            <button onClick={() => __loginWithAzure()} type="button">Login using Azure AD</button>
          </div>
        </form>
      </div>
      {state.showPassCodePopup || state.formSubmitted ? (
        <div className="login-popup">
          <Popup
            header={
              state.showPassCodePopup
                ? __renderPassCodePopupHeader()
                : state.formSubmitted
                  ? __renderLoginError()
                  : null
            }
            content={
              state.showPassCodePopup
                ? __renderPassCodeFields()
                : state.formSubmitted
                  ? __renderLoginContent()
                  : null
            }
            footer={null}
          />
        </div>
      ) : null}

      {resetPasswordShow ? (
        <ResetPasswordModal token={state.secondValidation.userToken} closeModal={() => setResetPasscodeShow(false)} />
      ) : null}

      {resetPasscodeShow ? (
        <div className="login-popup">
          <Popup
            header={__renderResetPasscodeTitle()}
            content={__renderPassCodeFields()}
            footer={null}
          />
        </div>
      ) : null}
    </>
  );
};

export default Auth;
