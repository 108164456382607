// Import React hooks and Kendo Grid components
import {
    Grid, GridColumn, GridEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridSelectionChangeEvent,
    GridHeaderSelectionChangeEvent,
    getSelectedState,
    GridExpandChangeEvent,
    GridCellProps,
    GridCustomCellProps
} from "@progress/kendo-react-grid"
import { useEffect, useState, useCallback, useRef, useContext, createContext } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { parseDataByType } from "../../../utils";
import { SortDescriptor, filterBy, getter, orderBy } from "@progress/kendo-data-query";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { contextmenu, details_icon } from "../../../assets/icons";
import { infinityLoader } from "../../../config/Images";
import { toast } from "react-toastify";
import EditCasePopup from "./editCaseDataPopup";
import { editCaseRecordById } from "../store/actions"
import { useDispatch } from "react-redux";




// Interface to type-check sort states
const initialSort: Array<SortDescriptor> = [
    { field: "id", dir: "asc" },
];

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "SELECTED_FIELD";
const EDITFIELD: string = "inEdit";

const idGetter = getter(DATA_ITEM_KEY);

const GridContaxt = createContext<any>({})

/**
 * KendoGrid component to handle displaying grid data.
 *
 * @param {any} props - The props passed from the parent component.
 * @returns {JSX.Element} - The rendered component.
 */
const KendoGrid: any = (props: any) => {

    const dispatch = useDispatch();

    // set minVisibleColumnCount based on window width
    const width = window.innerWidth * 0.2;
    const windowWidth = (window.innerWidth - (width > 360 ? width : 360)) / 130;
    const minVisibleColumnCount = parseInt(windowWidth.toFixed(0));

    // const _grid = useRef<any>(null);
    const _export = useRef<any>();

    // State management
    const [gridData, setGridData] = useState<any>(null);
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [isPaginated, setIsPaginated] = useState<any>(props.isPaginated);

    const [isHiddenColumn, setIsHiddenColumn] = useState<boolean>(false);
    const [hidenColumnCount, setHidenColumnCount] = useState<number>(0);
    const [selectedState, setSelectedState] = useState<{ [id: string]: boolean | number[]; }>({});
    const [toggleDropDown, setToggleDropDown] = useState<boolean>(false);
    const [toggleDropDownXY, setToggleDropDownXY] = useState<any>({
        x: 0, y: 0
    });
    const [filter, setFilter] = useState<any>();
    const [selectedRecord, setSelectedRecord] = useState<any>(null);

    const [editCasePopup, setEditCasePopup] = useState<any>(false);
    const [editCaseData, setEditCaseData] = useState<any>(null);


    const [dataState, setDataState] = useState<any>(props.dataState);

    const [sort, setSort] = useState(initialSort);

    const [pageSize, setPageSize] = useState<number>(10);

    // Event handler for pagination change
    const pageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent;
        const take = event.page.take;

        if (targetEvent.value) {
            setPageSize(targetEvent.value);
        }

        // setDataState({
        //     ...event.page,
        //     take,
        // });

        props.onPageChange(event);
    };


    useEffect(() => {
        document.addEventListener("asideToggle", (e) => __triggerAsideToggle());
        document.addEventListener("exportData", (e) => exportExcel());
        document.addEventListener("expandColumns", (e) => setIsHiddenColumn(isHiddenColumn => !isHiddenColumn));

        // return document.removeEventListener("asideToggle", (e) => __triggerAsideToggle);
    }, [])

    // Data processing useEffect
    useEffect(() => {
        if (props.gridData !== null) {

            if (props.gridData.gridColumns.length > minVisibleColumnCount) {
                const hiddenColumnsCount = props.gridData.gridColumns.length - minVisibleColumnCount;
                setIsHiddenColumn(true)
                setHidenColumnCount(hiddenColumnsCount)
                props.onHiddenCount(hiddenColumnsCount)
            }

            setGridData(props.gridDataRows);

            if (isPaginated !== props.isPaginated) {
                setIsPaginated(props.isPaginated);
            }

            if (props.dataState.skip !== dataState.skipp) {
                setDataState(props.dataState)
            }

        }
    }, [props]);

    useEffect(() => {
        setPageSize(props.pageSize.value !== "" ? props.pageSize.value : 10)
    }, [props.pageSize])

    const __triggerAsideToggle = () => {
        setIsHiddenColumn(false)
    }

    const _customActions = (props: GridCellProps) => {
        // const { editGrid, detailedGrid, removeGrid } = useContext(GridContaxt);
        return (<td>
            <div className="d-flex">
                <button className="btn btn-sm" onClick={(e) => {
                    setToggleDropDownXY({ x: e.clientX, y: e.clientY })
                    setToggleDropDown(!toggleDropDown)
                    setSelectedRecord(props.dataItem)
                }}><img src={contextmenu} style={{ width: 20 }} /></button>

                <button className="btn btn-sm" onClick={() => detailedGrid(props.dataItem)}><img src={details_icon} style={{ width: 20 }} /></button>
            </div>
        </td>);
    }

    const __renderGridToolbar = () => {
        if (!hidenColumnCount) {
            return null
        }

        return <GridToolbar>
            {/* <div className="export-btns-container">
                <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => exportExcel()}>Export to Excel</button>
            </div> */}

            {/* <div className="pr-1 d-inline-block"></div>
            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => { }}>Select</button> */}
            <div className="pr-1 d-inline-block"></div>

            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => {
                setIsHiddenColumn(!isHiddenColumn);
            }}>{isHiddenColumn ? "+" : "-"} {hidenColumnCount}</button>
        </GridToolbar>
    }

    const __onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {

            const newSelectedState: any = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const __onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
        },
        []
    );

    const exportExcel = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const __renderColumns = () => {
        const columns: any = []
        columns.push(<GridColumn field={SELECTED_FIELD} filterable={false} width={40} />)
        columns.push(<GridColumn cell={_customActions} filterable={false} title="Actions" width={100} />)


        props.gridData.gridColumns.map((tdCol: any, index: number) => {
            if (tdCol.isVisible) {
                // optimization for hidden columns
                const grid = <GridColumn
                    key={`column_name_${tdCol.name.trim()}`}
                    field={tdCol.name.trim()}
                    title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                    width={tdCol.width === 0 ? "auto" : tdCol.width}
                // editable={["CaseOfficer", "CaseStatus"].indexOf(tdCol.name) > -1 ? true : false}
                />

                if (isHiddenColumn) {
                    if (index < minVisibleColumnCount) {
                        columns.push(grid)
                    }
                } else {
                    columns.push(grid)
                }
            }
        })

        return columns
    }


    const __expandChange = (event: GridExpandChangeEvent) => {
        if (event.dataItem !== "") {
            props.onExpand(event.dataItem)

            if (!expandedRows.includes(event.dataItem.Group_Ref)) {
                setExpandedRows((expandedRows: any) => [...expandedRows, event.dataItem.Group_Ref])
            }
        }
    }


    const editGrid = (dataItem: any) => {
        console.log("InEdit: ", dataItem)
        setEditCasePopup(true)
        setEditCaseData(dataItem)
        // props.customAction({ dataItem, action: "edit" })
    }


    const removeGrid = (dataItem: any) => {
        props.customAction({ dataItem, action: "delete" })
    }


    const detailedGrid = (item: any) => {
        props.onCaseDetails(item)
    };


    const __onDataStateChange = (e: any) => {
        setDataState(e.dataState);
    }

    const __renderDropdown = () => {
        return toggleDropDown ? <><div className="" style={{ position: "fixed", top: (toggleDropDownXY.y + 10), left: (toggleDropDownXY.x - 50), zIndex: 999 }}>
            <ul className={`dropdown-menu ${toggleDropDown ? "show" : "hide"}`}>
                <li><button className="btn btn-sm btn-block" onClick={() => {
                    setToggleDropDown(false)
                    editGrid(selectedRecord)
                }}>Edit</button></li>
                <li><button className="btn btn-sm btn-block" onClick={() => {
                    setToggleDropDown(false)
                    removeGrid(selectedRecord)
                }}>Delete</button></li>
            </ul>
        </div>
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(255,255,255,0)", zIndex: 889 }}
                onClick={(e: any) => {
                    setToggleDropDown(false)
                }}></div>
        </> : null
    }


    const __showDetails = (props: GridCustomCellProps) => {
        if (props.dataItem === null || props.dataItem === undefined) {
            return null
        }

        return <div style={{ overflow: "scroll" }}>
            <Grid
                key={"childdata"}
                style={{ minWidth: "2580px" }}
                // {...props}
                data={props.dataItem.details}
                resizable={true}
            >

            </Grid>
        </div>

    }

    const __onExportComplete = (e: any) => {
        toast.success("Data exported successfully")
    }

    const __submitEditCaseRecord = (e: any) => {
        try {
            dispatch(editCaseRecordById({
                token: props.token,
                params: {
                    controlId: props.controlId,
                    dspId: props.dspId,
                    id: editCaseData.DgsId,
                    oneViewReference: editCaseData.Group_Ref
                },
                body: e
            }))
        } catch (e) {
            toast.error("Something went wrong")
        }
    }


    // No data, returning empty message
    if (gridData === null) {
        return <div className="p-2">{props.gridData.emptyMessage}</div>
    }



    // Main component render
    return (
        <div>

            {__renderDropdown()}
            {/* Render toolbar  */}
            {__renderGridToolbar()}

            {editCasePopup && (<EditCasePopup
                token={props.token}
                caseOfficer={editCaseData.CaseOfficer}
                caseStatus={editCaseData.CaseStatus}
                closeModal={() => {
                    setEditCasePopup(false);
                    setEditCaseData(null);
                }}
                onSubmit={(e: any) => __submitEditCaseRecord(e)} />)}

            {props.isFetching ? <div className="loading-data"><img src={infinityLoader} alt="Loading" /></div> : null}

            <ExcelExport
                data={(gridData.data.filter((item: any) => {
                    return selectedState[idGetter(item)]
                }))}
                onExportComplete={(e) => __onExportComplete(e)}
                fileName="case-data.xlsx"
                ref={_export}
            >
                <Grid



                    data={orderBy(filterBy(gridData.data.map((item: any) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    })), filter), sort)}
                    total={gridData.total}
                    columnVirtualization={false}

                    style={{ overflowX: "scroll", maxHeight: "65vh" }}
                    scrollable={true}

                    skip={dataState.skip}
                    take={dataState.take}

                    onPageChange={pageChange}

                    pageSize={pageSize}

                    filterable={false}
                    filter={filter}
                    onFilterChange={(e) => setFilter(e.filter)}
                    // onScroll={scrollHandler}
                    // fixedScroll={true}

                    pageable={isPaginated ? {
                        buttonCount: 10,
                        pageSizes: [10, 25, 50, 100],
                        pageSizeValue: pageSize,
                    } : false}

                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    onSelectionChange={__onSelectionChange}
                    onHeaderSelectionChange={__onHeaderSelectionChange}

                    resizable={true}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}

                    detail={(res: any) => __showDetails(res)}
                    expandField="details"
                    onExpandChange={(e) => __expandChange(e)}

                    {...dataState}
                    onDataStateChange={(e) => __onDataStateChange(e)}
                // editField={EDITFIELD}
                >                        {/* Render columns */}
                    {__renderColumns()}
                </Grid>
            </ExcelExport>
        </div>
    )
}

export default KendoGrid;