import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { resetPassword, resetResetPassword } from "../store/actions";
import { Popup } from "../../dashboard/component";
import { closeIcon } from "../../../assets/icons";
import { resetEditCaseRecordById } from "../store/actions";

const EditCasePopup = (props: any) => {
    const dispatch = useDispatch();
    const editCaseData = useSelector((state: any) => state.editCaseDetails);
    const [caseOfficer, setCaseOfficer] = useState({ value: props.caseOfficer, error: false });
    const [caseStatus, setCaseStatus] = useState({ value: props.caseStatus, error: false });


    // useEffect(() => {
    //     if (props.token !== "" && props.token !== token) {
    //         setToken(props.token);
    //     }
    // }, [props]);

    useEffect(() => {
        if (editCaseData.isSuccess && editCaseData.data !== null) {
            toast.success("Case Details updated successfully")

            props.closeModal();
            dispatch(resetEditCaseRecordById())
        } else if (editCaseData.isError) {
            props.closeModal();

            console.log(editCaseData.data.error.message)
            toast.error(editCaseData.data.error.message)
            dispatch(resetEditCaseRecordById())
        }

    }, [editCaseData])

    const __submitCaseDetails = (e: any) => {
        e.preventDefault();

        try {
            if (caseOfficer.value === "" || caseStatus.value === "") {
                toast.error("All fields are required")
                return
            } else {
                props.onSubmit([{
                    "controlName": "CaseOfficer",
                    "values": caseOfficer.value
                }, {
                    "controlName": "CaseStatus",
                    "values": caseStatus.value
                }])
            }
        } catch (e) {
            toast.error("Something went wrong")
        }

    }

    const __renderCaseEditDetailsTitle = () => {
        return (<div className="popup-title">
            <h3 className="title">Edit Case</h3>
            <button
                className="close"
                onClick={() => props.closeModal()}
            >
                <img src={closeIcon} alt="Close icon" />
            </button>
        </div>)
    }


    // function to render the fields for the popup of passcode validation
    const __renderCaseEditDetails = () => {
        return (
            <div className="passcode-form-wrap p-2">
                <form onSubmit={(e) => __submitCaseDetails(e)}>
                    <div className="filter-fields p-3">
                        <h3 className="title">Case Officer</h3>
                        <input type="text" name={"caseOfficer"} id={"caseOfficer"} onChange={(e) => setCaseOfficer({ value: e.target.value, error: e.target.value === "" })} />
                        {caseOfficer.error ? <p className="text-danger">Case officer is required</p> : null}
                    </div>
                    <div className="filter-fields p-3">
                        <h3 className="title">Case Status</h3>
                        <input type="text" name={"caseStatus"} id={"caseStatus"} onChange={(e) => setCaseStatus({ value: e.target.value, error: e.target.value === "" })} />
                        {caseStatus.error ? <p className="text-danger">Case status is required</p> : null}
                    </div>

                    <div className="p-3">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        );
    };


    return (<div className="edit-case">
        <Popup
            header={__renderCaseEditDetailsTitle()}
            content={__renderCaseEditDetails()}
            footer={null}
        />
    </div>)
}

export default EditCasePopup;